
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/presentation/helper/Dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import SwalNotification from "../plugins/SwalNotification";
import { CUSTOM_ERROR_MESSAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { BarnyardListModel } from "@/domain/barnyard/barnyardList/model/BarnyardListModel";
import { AnimalTransferModel } from "@/domain/animal/animalTransfer/model/AnimalTransferModel";

export default defineComponent({
  name: "new-target-modal",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const swalNotification = new SwalNotification();

    const animalTransferModel = ref<AnimalTransferModel>({
      barnyard: 0,
    });
    const barnyardList = ref<BarnyardListModel[]>([]);

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const barnyardController = store.state.ControllersModule.barnyardController;
    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }
    if (!barnyardController) {
      throw new Error("BarnyardController is not available in Vuex store.");
    }

    //Create form validation object
    const form = Yup.object().shape({
      barnyard: Yup.string()
        .test(
          "isRequired",
          t(CUSTOM_ERROR_MESSAGES.required),
          function (value) {
            return value !== "0" && value !== null;
          }
        )
        .label("Barnyard"),
      date: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Date"),
      description: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Description"),
    });

    const getBarnyardList = () => {
      barnyardList.value = [];

      const barnyardListModel: BarnyardListModel = {
        barnyard: {},
        page: 1,
        pageSize: 50,
      };

      barnyardController
        .barnyardList(barnyardListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((barnyard) => {
              totalItems.value = barnyard.pagination.total;
              currentPage.value = barnyard.pagination.page;
              pageCount.value = barnyard.pagination.pageCount;
              barnyardList.value.push(barnyard);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const submit = () => {
      loading.value = true;
      animalTransferModel.value.animal = Number(store.getters.selectedAnimalId);

      animalController
        .animalTransfer(animalTransferModel.value)
        .then((response) => {
          store.dispatch("newTransfer", store.getters.newTransfer + 1 );
          loading.value = false;
          if (response.getValue()) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_TRANSFER_ANIMAL),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      getBarnyardList();
    });

    return {
      animalTransferModel,
      submit,
      loading,
      formRef,
      newTargetModalRef,
      barnyardList,
      form,
    };
  },
});
